
import { defineComponent, PropType } from 'vue'
import { NotificationType } from '@/types/Notification'

export default defineComponent({
  name: 'DefaultNotification',

  props: {
    type: {
      type: String as PropType<NotificationType>,
      default: ''
    },
    text: {
      type: String as PropType<string>,
      default: ''
    }
  }
})
