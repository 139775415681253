
import { defineComponent, ref } from 'vue'
import useSignup from '../composables/useSignup'
import DefaultNotification from '@/components/layout/notification/DefaultNotification.vue'
import DefaultLoading from '@/components/layout/loading/DefaultLoading.vue'
import AuthFormLink from './AuthFormLink.vue'
import { Auth } from '../types/Auth'

export default defineComponent({
  name: 'SignupForm',

  components: {
    AuthFormLink,
    DefaultNotification,
    DefaultLoading
  },

  setup(props, { emit }) {
    const { error, notification, signup, isPending } = useSignup()
    const formData = ref<Auth>({
      email: 'betogarcia@gmail.com',
      password: '123456',
      passwordConfirm: '123456',
      displayName: 'Beto Garcia'
    })

    const handleSubmit = async () => {
      await signup(formData.value)
      if (!error.value) {
        emit('signup')
      }
    }

    return { formData, handleSubmit, error, notification, signup, isPending }
  }
})
