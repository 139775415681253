
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Notfound',

  setup() {
    const router = useRouter()
    const backPage = () => {
      router.go(-1)
    }

    return { backPage }
  }
})
