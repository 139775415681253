
import { defineComponent, PropType } from 'vue'
import Job from '@/modules/core/home/types/Job'
export default defineComponent({
  name: 'UserList',

  props: {
    job: {
      type: Object as PropType<Job>,
      required: true
    }
  },
  setup() {
    return {}
  }
})
