
import { defineComponent, ref } from 'vue'
import useLogin from '../composables/useLogin'
import DefaultNotification from '@/components/layout/notification/DefaultNotification.vue'
import DefaultLoading from '@/components/layout/loading/DefaultLoading.vue'
import AuthFormLink from './AuthFormLink.vue'
import { Auth } from '../types/Auth'

export default defineComponent({
  name: 'LoginForm',

  components: {
    AuthFormLink,
    DefaultNotification,
    DefaultLoading
  },
  setup(props, { emit }) {
    const { error, notification, isPending, login, loginWithGoogle } =
      useLogin()
    const formData = ref<Auth>({
      email: 'betogroo@beto.com',
      password: '123456'
    })
    const handleSubmit = async (): Promise<void> => {
      await login(formData.value)
      if (!error.value) {
        emit('login')
      }
    }

    const handleClick = async () => {
      await loginWithGoogle()
      if (!error.value) {
        emit('login')
      }
    }
    return {
      formData,
      handleSubmit,
      handleClick,
      error,
      notification,
      isPending
    }
  }
})
