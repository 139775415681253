
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import getUser from '@/modules/core/auth/composables/getUser'
import useLogout from '@/modules/core/auth/composables/useLogout'

export default defineComponent({
  setup() {
    const router = useRouter()
    const { user } = getUser()
    const { error, logout: logoutSystem, isPending, notification } = useLogout()
    const logout = async () => {
      await logoutSystem()
      if (!error.value) {
        router.push({ name: 'Welcome' })
      }
    }
    return {
      logout,
      error,
      isPending,
      notification,
      user
    }
  }
})
