import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h4", null, "Order by " + _toDisplayString(_ctx.order), 1),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedUsers, (user) => {
        return (_openBlock(), _createElementBlock("li", {
          key: user.uid
        }, _toDisplayString(`${user.uid} - ${user.displayName} - ${user.email} - ${
          user.photoURL || ''
        }`), 1))
      }), 128))
    ])
  ], 64))
}