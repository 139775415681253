
import { defineComponent, ref } from 'vue'
import userResetPassword from '../composables/useResetPassword'
import DefaultNotification from '@/components/layout/notification/DefaultNotification.vue'
import DefaultLoading from '@/components/layout/loading/DefaultLoading.vue'
import AuthFormLink from './AuthFormLink.vue'
import { Auth } from '../types/Auth'

export default defineComponent({
  name: 'ResetPasswordForm',

  components: {
    AuthFormLink,
    DefaultNotification,
    DefaultLoading
  },

  setup(props, { emit }) {
    const { error, notification, isPending, reset } = userResetPassword()
    const formData = ref<Auth>({
      email: 'beto@beto.com',
      password: ''
    })

    const handleSubmit = async () => {
      await reset(formData.value)
      if (!error.value) {
        emit('reset')
      }
    }

    return { formData, handleSubmit, error, notification, isPending }
  }
})
