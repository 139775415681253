<template>
  <div class="about">
    <h1>This is an about page</h1>
    <div
      class="wistia_responsive_padding"
      style="padding: 56.25% 0 0 0; position: relative"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
      >
        <iframe
          src="https://fast.wistia.net/embed/iframe/r24zso9dmy?videoFoam=true"
          title="dome Video"
          allow="autoplay; fullscreen"
          allowtransparency="true"
          frameborder="0"
          scrolling="no"
          class="wistia_embed"
          name="wistia_embed"
          allowfullscreen
          msallowfullscreen
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  </div>
</template>
