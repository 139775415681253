
import { defineComponent } from 'vue'
import getUser from '@/modules/core/auth/composables/getUser'

export default defineComponent({
  name: 'Profile',

  setup() {
    const { user } = getUser()

    return { user }
  }
})
