import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", null, "Página de " + _toDisplayString(`${_ctx.user.displayName} (${_ctx.user.email})`), 1),
        _createElementVNode("h2", null, _toDisplayString(_ctx.user.uid), 1)
      ]))
    : _createCommentVNode("", true)
}