
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { LoginForm, SignupForm, ResetPasswordForm } from '../components/'

export default defineComponent({
  name: 'Welcome',

  props: {
    mode: {
      type: String as PropType<string>,
      default: 'login'
    }
  },

  components: {
    LoginForm,
    SignupForm,
    ResetPasswordForm
  },

  setup() {
    const router = useRouter()
    const gotoPage = (name: string) => {
      router.push({ name })
    }
    return { gotoPage }
  }
})
