
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AuthFormLink',

  props: {
    text: {
      type: String,
      default: ''
    },
    textLink: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {}
  }
})
